import Vue from 'vue';
import Router from 'vue-router';
import i18n from '@/i18n';

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        // Login
        {
          name: 'base',
          path: '',
          component: () => import('@/views/pages/Login'),
          meta: {
            title: i18n.t('login.title')
          }
        },
        {
          name: 'login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
          meta: {
            title: i18n.t('login.title')
          }
        },
        {
          name: 'home',
          path: 'home',
          component: () => import('@/views/pages/Home'),
          meta: {
            title: i18n.t('app.name')
          }
        },
        {
          name: 'logout',
          path: 'logout',
          component: () => import('@/views/pages/Logout'),
          meta: {
            title: i18n.t('logout.title')
          }
        },
        // Orders
        {
          name: 'dashboard',
          path: 'dashboard',
          component: () => import('@/views/pages/location/Dashboard'),
          meta: {
            title: i18n.t('dashboard.title')
          }
        },
        {
          name: 'preparation',
          path: 'preparation',
          component: () => import('@/views/pages/location/Preparation'),
          meta: {
            title: i18n.t('preparation.subtitle')
          }
        },
        {
          name: 'pickup',
          path: 'pickup',
          component: () => import('@/views/pages/location/Pickup'),
          meta: {
            title: i18n.t('pickup.subtitle')
          }
        },
        {
          name: 'driverdashboard',
          path: 'driverdashboard',
          component: () => import('@/views/pages/location/DriverDashboard'),
          meta: {
            title: i18n.t('driverDashboard.subtitle')
          }
        },
        {
          name: 'delivery',
          path: 'delivery',
          component: () => import('@/views/pages/location/Delivery'),
          meta: {
            title: i18n.t('delivery.sub.all.subtitle')
          }
        },
        {
          name: 'mydelivery',
          path: 'mydelivery',
          component: () => import('@/views/pages/location/MyDelivery'),
          meta: {
            title: i18n.t('delivery.sub.my.subtitle')
          }
        },
        {
          name: 'orders',
          path: 'orders',
          component: () => import('@/views/pages/location/Orders'),
          meta: {
            title: i18n.t('order.subtitle')
          }
        },
        {
          name: 'customer-service/weather',
          path: 'customer-service/weather',
          component: () => import('@/views/pages/customerService/Weather'),
          meta: {
            title: i18n.t('customerService.weather.subtitle')
          }
        },
        {
          name: 'customer-service/guide',
          path: 'customer-service/guide',
          component: () => import('@/views/pages/customerService/Guide'),
          meta: {
            title: i18n.t('customerService.guide.subtitle')
          }
        },
        {
          name: 'customer-service/orders',
          path: 'customer-service/orders',
          component: () => import('@/views/pages/customerService/Orders'),
          meta: {
            title: i18n.t('customerService.orders.subtitle')
          }
        },
        {
          name: 'customer-service/payments',
          path: 'customer-service/payments',
          component: () => import('@/views/pages/customerService/MetaPayments'),
          meta: {
            title: i18n.t('customerService.payments.title')
          }
        },
        {
          name: 'customer-service/prospects',
          path: 'customer-service/prospects',
          component: () => import('@/views/pages/customerService/MetaProspects'),
          meta: {
            title: i18n.t('customerService.prospects.title')
          }
        },
        {
          name: 'customer-service/new-customers',
          path: 'customer-service/new-customers',
          component: () => import('@/views/pages/customerService/MetaNewCustomers'),
          meta: {
            title: i18n.t('customerService.newCustomers.title')
          }
        },
        // {
        //   name: 'customer-service/new-market-customers',
        //   path: 'customer-service/new-market-customers',
        //   component: () => import('@/views/pages/customerService/MetaNewMarketCustomers'),
        //   meta: {
        //     title: i18n.t('customerService.newMarketCustomers.title')
        //   }
        // },
        {
          name: 'customer-service/inactive-customers/v2',
          path: 'customer-service/inactive-customers/v2',
          component: () => import('@/views/pages/customerService/MetaInactiveCustomersV2'),
          meta: {
            title: i18n.t('customerService.inactiveCustomers.title')
          }
        },
        {
          name: 'customer-service/users',
          path: 'customer-service/users',
          component: () => import('@/views/pages/customerService/MetaUsers'),
          meta: {
            title: i18n.t('customerService.users.subtitle')
          }
        },
        {
          name: 'search',
          path: 'search',
          component: () => import('@/views/pages/Search'),
          meta: {
            title: i18n.t('search.subtitle')
          }
        },
        // Distribution
        {
          name: 'distribution',
          path: 'distribution',
          component: () => import('@/views/pages/location/Distribution'),
          meta: {
            title: i18n.t('distribution.subtitle')
          }
        },
        {
          name: 'prospectingZones',
          path: 'prospecting/zones',
          component: () => import('@/views/pages/meta/prospecting/ProspectingZones'),
          meta: {
            title: i18n.t('prospecting.zones.subtitle')
          }
        },
        {
          name: 'prospectingDiscounts',
          path: 'prospecting/discounts',
          component: () => import('@/views/pages/meta/prospecting/ProspectingDiscounts'),
          meta: {
            title: i18n.t('prospecting.discounts.subtitle')
          }
        },
        {
          name: 'prospectingResults',
          path: 'prospecting/results',
          component: () => import('@/views/pages/meta/prospecting/ProspectingResults'),
          meta: {
            title: i18n.t('prospecting.results.subtitle')
          }
        },
        // Marketing
        {
          name: 'marketing/campaigns',
          path: 'marketing/campaigns',
          component: () => import('@/views/pages/location/marketing/Campaigns'),
          meta: {
            title: i18n.t('marketing.campaign.list.subtitle')
          }
        },
        // Purchase
        {
          name: 'purchase/merchandises',
          path: 'purchase/merchandises',
          component: () => import('@/views/pages/meta/purchase/Merchandises'),
          meta: {
            title: i18n.t('purchase.merchandise.subtitle')
          }
        },
        {
          name: 'purchase/analytics',
          path: 'purchase/analytics',
          component: () => import('@/views/pages/meta/purchase/PurchaseAnalytics'),
          meta: {
            title: i18n.t('purchase.analytic.subtitle')
          }
        },
        // User Settings
        {
          name: 'usersettings',
          path: 'usersettings',
          component: () => import('@/views/pages/UserSettings'),
          meta: {
            title: i18n.t('userSettings.subtitle')
          }
        },
        // Administration location
        {
          name: 'businessHours',
          path: 'businessHours',
          component: () => import('@/views/pages/location/admin/BusinessHours'),
          meta: {
            title: i18n.t('admin.sub.businessHours.title')
          }
        },
        {
          name: 'tasks/list',
          path: 'tasks/list',
          component: () => import('@/views/pages/location/admin/ProductionTasks'),
          meta: {
            title: i18n.t('admin.sub.tasks.title')
          }
        },
        {
          name: 'users/list',
          path: 'users/list',
          component: () => import('@/views/pages/location/admin/Users'),
          meta: {
            title: i18n.t('admin.sub.users.title')
          }
        },
        {
          name: 'users/schedule',
          path: 'users/schedule',
          component: () => import('@/views/pages/location/admin/UsersSchedule'),
          meta: {
            title: i18n.t('admin.sub.usersSchedule.subtitle')
          }
        },
        {
          name: 'deliveryZones',
          path: 'delivery/zones',
          component: () => import('@/views/pages/location/admin/DeliveryZones'),
          meta: {
            title: i18n.t('admin.sub.deliveryZones.subtitle')
          }
        },
        // Administration meta
        {
          name: 'adminDistributionZone',
          path: 'admin/distribution/zones',
          component: () => import('@/views/pages/meta/admin/AdminDistributionZone'),
          meta: {
            title: i18n.t('admin.sub.distribution.subtitle')
          }
        },
        {
          name: 'adminPrintDiscounts',
          path: 'admin/print/discounts',
          component: () => import('@/views/pages/meta/admin/AdminPrintDiscounts'),
          meta: {
            title: i18n.t('admin.sub.printDiscounts.subtitle')
          }
        },
        {
          name: 'producttypes',
          path: 'product/types',
          component: () => import('@/views/pages/meta/product/ProductTypes'),
          meta: {
            title: i18n.t('admin.sub.productTypes.subtitle')
          }
        },
        {
          name: 'productcategories',
          path: 'product/categories',
          component: () => import('@/views/pages/meta/product/ProductCategories'),
          meta: {
            title: i18n.t('admin.sub.productCategories.subtitle')
          }
        },
        {
          name: 'productgroups',
          path: 'product/groups',
          component: () => import('@/views/pages/meta/product/ProductGroups'),
          meta: {
            title: i18n.t('admin.sub.productGroups.subtitle')
          }
        },
        {
          name: 'productoptions',
          path: 'product/options',
          component: () => import('@/views/pages/meta/product/ProductOptions'),
          meta: {
            title: i18n.t('admin.sub.productOptions.subtitle')
          }
        },
        {
          name: 'products',
          path: 'products',
          component: () => import('@/views/pages/meta/product/Products'),
          meta: {
            title: i18n.t('admin.sub.products.title')
          }
        },
        // Location Reports
        {
          name: 'reports',
          path: 'reports',
          component: () => import('@/views/pages/location/Reports'),
          meta: {
            title: i18n.t('report.activity.title')
          }
        },
        {
          name: 'accounting',
          path: 'accounting',
          component: () => import('@/views/pages/location/Accounting'),
          meta: {
            title: i18n.t('report.accounting.title')
          }
        },
        // Meta Reports
        {
          name: 'meta_activity',
          path: 'meta/activity',
          component: () => import('@/views/pages/meta/analytics/MetaReports'),
          meta: {
            title: i18n.t('metaReport.metaActivity.title')
          }
        },
        {
          name: 'meta_crm',
          path: 'meta/crm',
          component: () => import('@/views/pages/meta/analytics/MetaCrm'),
          meta: {
            title: i18n.t('metaReport.metaCrm.title')
          }
        },
        {
          name: 'meta_quality',
          path: 'meta/quality',
          component: () => import('@/views/pages/meta/analytics/MetaQuality'),
          meta: {
            title: i18n.t('metaReport.metaQuality.subtitle')
          }
        },
        {
          name: 'meta_performance',
          path: 'meta/performance',
          component: () => import('@/views/pages/meta/analytics/MetaPerformance'),
          meta: {
            title: i18n.t('metaReport.metaPerformance.subtitle')
          }
        },
        {
          name: 'meta_accounting',
          path: 'meta/accounting',
          component: () => import('@/views/pages/meta/analytics/MetaAccounting'),
          meta: {
            title: i18n.t('metaReport.metaAccounting.title')
          }
        },
        // Meta / Access to all locations
        {
          name: 'overview',
          path: 'overview',
          component: () => import('@/views/pages/meta/Overview'),
          meta: {
            title: i18n.t('overview.subtitle')
          }
        },
        {
          name: 'order_editor',
          path: 'order/editor',
          component: () => import('@/views/pages/meta/OrderEditor'),
          meta: {
            title: i18n.t('orderEditor.subtitle')
          }
        }
      ]
    }
  ]
});

const initializationTime = Date.now();
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = i18n.t('app.name') + (to.meta.title !== undefined ? ' | ' + to.meta.title : '');

    if (Date.now() - initializationTime > 5 * 60 * 60 * 1000) {
      window.location.reload();
    }
  });
});

export default router;
